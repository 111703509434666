<template>
  <area-documents-layout url="/api/project/realization" />
</template>

<script>
import AreaDocumentsLayout from '@/views/project/layouts/AreaDocumentsLayout'

export default {
  name: 'Realization',
  components: { AreaDocumentsLayout }
}
</script>
